import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";

const winTokenAddress = "0x25d887Ce7a35172C62FeBFD67a1856F20FaEbB00";
const bscScanApiKey = "TYCNTDYS1Q7DGTZ3NM5VGUCTA2JY859T5S";
const backendUrl = "https://outstanding-nourishment-production.up.railway.app";

function App() {
  const [wallet, setWallet] = useState(null);
  const [connected, setConnected] = useState(false);
  const [balance, setBalance] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [referralLink, setReferralLink] = useState(""); // Store referral link
  const [showReferralPopup, setShowReferralPopup] = useState(false); // Manage popup visibility

  const checkInReward = 200;
  const inviteReward = 500;

  const generateReferralLink = () => {
    if (!wallet) {
      setMessage("Please connect your wallet first.");
      return;
    }
    const link = `${window.location.origin}?ref=${wallet}`; // Generate referral link
    setReferralLink(link);
    setShowReferralPopup(true); // Show the popup/modal
  };

  const handleCheckIn = async () => {
    try {
      const response = await axios.post(`${backendUrl}/api/check-in`, { wallet }); // Add /api here
      setMessage(response.data.message);
      setLeaderboard((prev) => [...prev, { wallet, rewards: response.data.rewards }]);
    } catch (err) {
      setMessage(err.response?.data || "Error during check-in.");
    }
  };

  const addBSCNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38",
            chainName: "Binance Smart Chain Mainnet",
            nativeCurrency: {
              name: "BNB",
              symbol: "BNB",
              decimals: 18,
            },
            rpcUrls: ["https://bsc-dataseed.binance.org/"],
            blockExplorerUrls: ["https://bscscan.com"],
          },
        ],
      });
    } catch (error) {
      console.error("Error adding BSC network:", JSON.stringify(error, null, 2));
    }
  };

  const switchToBSC = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x38" }], // BSC Mainnet Chain ID
      });
    } catch (error) {
      if (error.code === 4902) {
        // Error 4902 means the network is not added to MetaMask
        console.log("BSC network is not added to MetaMask. Adding it now...");
        await addBSCNetwork();
      } else {
        console.error("Error switching to Binance Smart Chain:", JSON.stringify(error, null, 2));
      }
    }
  };

  useEffect(() => {
    async function fetchLeaderboard() {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://outstanding-nourishment-production.up.railway.app/api/leaderboard"
        );
        setLeaderboard(response.data);
      } catch (error) {
        console.error("Error fetching leaderboard:", JSON.stringify(error, null, 2));
        setMessage("Error fetching leaderboard.");
      } finally {
        setLoading(false);
      }
    }
    fetchLeaderboard();
  }, []);

  const connectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await window.ethereum.request({ method: "eth_accounts" });
        const walletAddress = accounts[0];
        setWallet(walletAddress);
        setConnected(true);
        await switchToBSC();
        fetchWalletBalance(walletAddress); // Fetch wallet balance
      } catch (error) {
        console.error("Error connecting wallet:", JSON.stringify(error, null, 2));
        setMessage("Failed to connect wallet.");
      }
    } else {
      alert("MetaMask is not installed. Please install MetaMask to use this DApp.");
    }
  };

  const fetchWalletBalance = async (address) => {
    try {
      const url = `https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${winTokenAddress}&address=${address}&tag=latest&apikey=${bscScanApiKey}`;
      const response = await axios.get(url);

      if (response.data.status === "1") {
        const rawBalance = response.data.result; // Balance in Wei
        const formattedBalance = parseFloat(rawBalance); // Convert to Ether (WIN)
        setBalance(formattedBalance);
      } else {
        console.error("Error fetching balance:", response.data.message);
        setMessage("Error fetching wallet balance.");
      }
    } catch (error) {
      console.error("Error fetching wallet balance:", JSON.stringify(error, null, 2));
      setMessage("Error fetching wallet balance.");
    }
  };

  return (
    <div id="app">
      <header className="App-header">
        <h1 className="app-title">WIN DApp</h1>
        <p className="subtitle">Connect your wallet to interact with the DApp!</p>
        <button className="button-primary" onClick={connectWallet}>
          {connected ? `Connected: ${wallet}` : "Connect Wallet"}
        </button>
        {message && <p className="message">{message}</p>}
        {connected && (
          <div className="wallet-info">
            <p>Wallet: {wallet}</p>
            <p>Balance: {balance !== undefined ? balance : 0} WIN</p>
          </div>
        )}
        <div className="rewards-section">
          <button className="button-primary" onClick={handleCheckIn}>
            Check-in Daily (+{checkInReward} WIN)
          </button>
          <button className="button-primary" onClick={generateReferralLink}>
            Invite a Friend (+{inviteReward} WIN)
          </button>
        </div>
        {showReferralPopup && (
          <div className="popup">
            <div className="popup-content">
              <h3>Your Referral Link</h3>
              <input type="text" value={referralLink} readOnly />
              <button onClick={() => navigator.clipboard.writeText(referralLink)}>
                Copy to Clipboard
              </button>
              <button onClick={() => setShowReferralPopup(false)}>Close</button>
            </div>
          </div>
        )}
        <h2 className="leaderboard-title">🏆 Leaderboard</h2>
        {loading ? (
          <p>Loading leaderboard...</p>
        ) : (
          <table id="leaderboard">
            <thead>
              <tr>
                <th>#</th>
                <th>Wallet Address</th>
                <th>Balance</th>
                <th>Rewards</th>
              </tr>
            </thead>
            <tbody>
              {leaderboard.length > 0 ? (
                leaderboard.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.address}</td>
                    <td>{Number(user.balance).toFixed(2)} WIN</td>
                    <td>{user.rewards ? user.rewards : 0} WIN</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </header>
    </div>
  );
}

export default App;
